import React, { useState } from "react";
import { Link } from "react-router-dom";
import useForm from "./useForm";
import validate from "./RegisterFormValidationRules";
import Checked from "../../assets/images/checked.png";
import Warning from "../../assets/images/warning.png";
import Info from "../../assets/images/info.svg";
import Instruction from "../../assets/images/linkedin-pdf-instruction.png";
import Cross from "../../assets/images/cross.png";
import BlueClose from "../../assets/images/blue-close.svg";
import axios from "axios";
import "./Register.scss";

const Register = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [modalImage, setModalImage] = useState(Checked);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [jobDescFile, setJobDescFile] = useState(null);
  const [offerCheckboxChecked, setOfferCheckboxChecked] = useState(false);

  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleJobDescFileChange = (event) => {
    const file = event.target.files[0];
    setJobDescFile(file || null);
  };

  const handleSubmitForm = async () => {
    setIsLoading(true);

    try {
      const requests = files.map((file) => {
        const formData = new FormData();
        formData.append("companyCode", values.companyCode);
        formData.append("cvFile", file);
        if (jobDescFile) {
          formData.append("jobDescFile", jobDescFile);
        }

        return axios.post(
          "https://europe-west9-heroic-purpose-420510.cloudfunctions.net/submit-cv",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      });

      const responses = await Promise.allSettled(requests);

      const successCount = responses.filter(
        (res) => res.status === "fulfilled" && res.value.status === 200
      ).length;

      setModalMessage(
        `${successCount} fichier(s) soumis avec succès. ${responses.length - successCount} fichier(s) échoué(s).`
      );
      setSubmitSuccess(true);
      setModalImage(successCount > 0 ? Checked : Warning);
    } catch (error) {
      console.error("Error during file submissions:", error);
      setModalMessage(
        "Une erreur s'est produite lors de la soumission. Veuillez réessayer."
      );
      setModalImage(Warning);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        onClose();
      }, 2000);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    validate,
    handleSubmitForm
  );

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "offerCheckbox") {
      setOfferCheckboxChecked(checked);
      if (!checked) {
        setJobDescFile(null);
      }
    }
    handleChange(e);
  };

  return (
    <>
      {!submitSuccess && (
        <div className="section is-fullheight">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="title">Déposer un CV</h1>
                <p>
                  Ce questionnaire a pour objectif de récolter quelques informations pour nous permettre de créer ton CV.
                </p>
                <button className="close-icon" onClick={onClose}>
                  <img src={Cross} alt="cross" />
                </button>
                <div className="box">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="field checkbox-wrap">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          checked={values.checkbox1 || false}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="checkbox1">Code de la société</label>
                      </div>
                      {errors.checkbox1 && (
                        <p className="help is-danger">{errors.checkbox1}</p>
                      )}
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <div className="control">
                          <label>Code de la société</label>
                          <input
                            className={`input ${
                              errors.companyCode && "is-danger"
                            }`}
                            type="text"
                            name="companyCode"
                            disabled={!values.checkbox1}
                            onChange={handleChange}
                            value={values.companyCode || ""}
                            placeholder=""
                          />
                        </div>
                        {errors.companyCode && (
                          <p className="help is-danger">{errors.companyCode}</p>
                        )}
                      </div>
                      <div className="field">
                        <div className="control">
                          <label>Nom de la société</label>
                          <input
                            className="input"
                            type="text"
                            disabled={!values.checkbox1}
                            name="companyName"
                            value={values.companyName || ""}
                            readOnly
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="field file-upload">
                      <div
                        className="control"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        Télécharger les CVs
                        <label htmlFor="cvFile">
                          {files.length > 0
                            ? `${files.length} fichier(s) sélectionné(s)`
                            : "Aucun fichier sélectionné"}
                        </label>
                        <input
                          id="cvFile"
                          name="cvFile"
                          type="file"
                          accept=".pdf,.docx"
                          onChange={handleFileChange}
                          multiple
                          style={{ marginTop: "15px", display: "none" }}
                        />
                      </div>
                      {files.map((file, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                          <p>{file.name}</p>
                          <img
                            src={BlueClose}
                            alt="Remove"
                            onClick={() => handleFileRemove(file)}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        </div>
                      ))}
                      {errors.cvFile && (
                        <p className="help is-danger">{errors.cvFile}</p>
                      )}
                    </div>
                  
                    <div className="field checkbox-wrap">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="offerCheckbox"
                          name="offerCheckbox"
                          checked={offerCheckboxChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="offerCheckbox">
                          Je dispose d'un appel d'offres pour la mission
                        </label>
                      </div>
                    </div>

                    <div className="field file-upload">
                      <div
                          className="control"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          Télécharger les fichiers
                        <input
                          id="jobDescFile"
                          name="jobDescFile"
                          type="file"
                          accept=".pdf,.docx"
                          onChange={handleJobDescFileChange}
                          disabled={!offerCheckboxChecked}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="jobDescFile"
                          className={`file-label ${
                            offerCheckboxChecked ? "" : "file-label-disabled"
                          }`}
                          style={{
                            display: "block",
                            marginTop: "10px",
                            color: offerCheckboxChecked ? "#5200FF" : "gray",
                          }}
                        >
                          {jobDescFile
                            ? jobDescFile.name
                            : "Aucun fichier sélectionné"}
                        </label>
                        </div>
                    </div>

                    <div className="field checkbox-wrap">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="checkbox3"
                          name="checkbox3"
                          onChange={handleCheckboxChange}
                          required
                        />
                        <label htmlFor="checkbox3">
                          J'accepte les<Link to="/">conditions générales</Link>d'ASAP-CV
                          ainsi que la
                          <a
                            href="/politique-de-confidentialité.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            politique de protection des données
                          </a>
                        </label>
                      </div>
                      {errors.checkbox3 && (
                        <p className="help is-danger">{errors.checkbox3}</p>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="button is-block is-info is-fullwidth"
                    >
                      {isLoading
                        ? "Envoi en cours..."
                        : "Envoyer les fichiers"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className={`submitmodal ${isModalOpen ? "is-active" : ""}`}>
          <div className="modal-background" onClick={onClose}></div>
          <div className="modal-content">
            <div className="box">
              <img src={modalImage} alt="" />
              <p>{modalMessage}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
