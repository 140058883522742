export default function validate(values) {
  let errors = {};
  if (values.checkbox1) {
    if (!values.companyCode) {
      errors.companyCode = "Le code de l'entreprise est obligatoire";
    }
  } else {
    errors.checkbox1 = "Le code de l'entreprise est requis.";
  }

  if (!values.checkbox1) {
    errors.checkbox1 = "Le code de l’entreprise est obligatoire.";
  }
  if (!values.checkbox3) {
    errors.checkbox3 = "Vous devez accepter les Termes et Conditions.";
  }

  return errors;
}
